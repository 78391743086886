import { graphql } from 'gatsby';

export const Fragment = graphql`
  fragment PackageContent on WpPackage {
    id
    template {
      __typename
      templateName
    }
    featuredImage {
      node {
        localFile {
          childImageSharp {
            gatsbyImageData(
              quality: 75
              transformOptions: { cropFocus: ENTROPY }
              layout: FULL_WIDTH
            )
          }
        }
        sourceUrl
        caption
        altText
        title
        id
        description
      }
    }
    content
    title
    slug
    uri
    seo {
      breadcrumbs {
        text
        url
      }
      canonical
      cornerstone
      focuskw
      metaDesc
      metaKeywords
      metaRobotsNofollow
      metaRobotsNoindex
      opengraphAuthor
      opengraphDescription
      opengraphImage {
        altText
        sourceUrl
        srcSet
      }
      opengraphModifiedTime
      opengraphPublishedTime
      opengraphPublisher
      opengraphSiteName
      opengraphTitle
      opengraphType
      opengraphUrl
      schema {
        articleType
        pageType
      }
      title
      twitterDescription
      twitterImage {
        altText
        sourceUrl
        srcSet
      }
      twitterTitle
    }
    locale {
      id
      locale
    }
    lang
    translated {
      id
      uri
      title
      lang
      locale {
        id
        locale
      }
    }
    packageCategories {
      nodes {
        id
        name
        uri
      }
    }
    booking {
      bookinginformation
      packagetime
      pricing
      url
    }
  }
`;

export default Fragment;
