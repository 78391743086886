/* eslint-disable react/no-danger */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { rem } from 'polished';
import { getSrc } from 'gatsby-plugin-image';
import { useTranslation } from 'react-i18next';
import Sticky from 'react-sticky-el';

import useLangRedirect from '../hooks/useLangRedirect';

import SEO from '../components/SEO';
import Layout from '../components/Layout';
import { GridContainer } from '../components/Grid';
import Row from '../components/Row';
import Col from '../components/Col';
import Hero from '../components/Hero';
import Button from '../components/Button';

import { breakpoint, breakpoints } from '../theme';
import { SiteInformation, PackageContent } from '../utils/fragments';

const Root = styled(Row)`
  position: relative;
  width: 100%;
  margin: ${rem(0)} auto 0;
  flex-flow: initial;
  display: block;
  padding-top: ${rem(50)};
  padding-bottom: 1000px;
  padding-bottom: ${rem(100)};
  /* max-width: ${(props) => props.theme.grid.maxWidthText}; */

  /* padding-bottom: ${rem(50)}; */

  ${breakpoint('mediumlarge')} {
    display: flex;
    /* padding-top: ${rem(64)}; */
    /* padding-bottom: ${rem(64)}; */
  }

  ${breakpoint('large')} {
    /* padding-top: ${rem(70)}; */
    /* padding-bottom: ${rem(70)}; */
  }
`;

const PostContent = styled.div`
  font-size: ${rem(16.5)};

  ${breakpoint('large')} {
    font-size: ${rem(18)};
  }

  & .wp-block-image {
    margin-top: ${rem(32)};
    margin-bottom: ${rem(22)};
  }

  & figcaption {
    color: ${(props) => props.theme.palette.colors.fadedText};
    font-size: ${rem(14)};
    font-weight: ${(props) => props.theme.typography.weights.primary.normal};
    font-family: ${(props) => props.theme.typography.fonts.primary};
  }
`;

const ContentCol = styled(Col)`
  position: relative;
  margin-bottom: ${rem(30)};
`;

const Sidebar = styled.aside`
  position: relative;
  background-color: ${(props) => props.theme.palette.colors.greyLight};
  padding: 2.85rem 2.5rem;
  border: ${rem(8)} solid ${(props) => props.theme.palette.background.content};
  box-shadow: 0px 0px 0px 1px ${(props) => props.theme.palette.colors.greyLight};
  margin: auto;
`;

const SidebarInner = styled.div`
  position: relative;
  padding: 0;
`;

const SidebarSection = styled.div`
  position: relative;
  padding: 0;
`;

const SidebarTitle = styled.h4`
  display: inline-block;
  margin: ${rem(15)} 0 0 0;
  font-size: ${rem(16)};
`;

const SidebarTitleMain = styled.h3``;

const Pricing = styled.p`
  margin: 0;
  font-size: ${rem(16)};
  font-weight: ${(props) => props.theme.typography.weights.primary.bold};
  padding-right: 1rem;
  flex-grow: 1;

  ${breakpoint('large')} {
    font-size: ${rem(18)};
  }
`;

const Period = styled.p`
  margin-bottom: ${rem(0)};
  font-size: ${rem(16)};
  font-weight: ${(props) => props.theme.typography.weights.primary.bold};
  padding-right: 1rem;
  flex-grow: 1;

  ${breakpoint('large')} {
    font-size: ${rem(18)};
  }
`;

const Description = styled.div`
  margin-top: ${rem(22)};

  > :last-child {
    margin-bottom: 0;
  }
`;

const CtaButton = styled(Button)`
  padding: 0.65rem 0.95rem;
  font-size: ${rem(16)};
  width: 100%;
  margin-top: ${rem(30)};

  ${breakpoint('mediumlarge')} {
    padding: 1rem;
  }
`;

const BookingInformation = styled(Sidebar)`
  &.stuck {
    ${breakpoint('mediumlarge')} {
      margin-top: ${rem(80)};
    }

    ${SidebarTitle} {
      display: none;

      ${breakpoint('mediumlarge')} {
        display: block;
      }
    }

    ${SidebarTitleMain} {
      display: none;

      ${breakpoint('mediumlarge')} {
        display: block;
      }
    }

    ${SidebarSection} {
      &.section-time {
        display: none;

        ${breakpoint('mediumlarge')} {
          display: block;
        }
      }

      &.section-pricing {
        flex-grow: 1;
      }
    }

    ${Description} {
      display: none;

      ${breakpoint('mediumlarge')} {
        display: block;
      }
    }

    ${CtaButton} {
      font-size: ${rem(13)};
      margin-top: 0;
      width: auto;

      ${breakpoint('mediumlarge')} {
        font-size: ${rem(16)};
        margin-top: ${rem(30)};
        width: 100%;
      }
    }

    ${SidebarInner} {
      display: flex;
      align-items: center;

      ${breakpoint('mediumlarge')} {
        display: block;
      }
    }
  }
`;

const SidebarCol = styled(Col)`
  position: relative;

  ${breakpoint('mediumlarge')} {
    width: ${rem(380)};
    flex-shrink: 0;
    z-index: 1;
  }

  .stickyBookingBox {
    .sticky {
      ${Sidebar} {
        padding: 0.85rem 1rem;

        ${breakpoint('mediumlarge')} {
          padding: 2.85rem 2.5rem;
        }
      }
    }
  }
`;

function Page({ data }) {
  const { page } = data;
  const { t } = useTranslation();
  const [isBookingStuck, setIsStuck] = useState(false);
  const [isMobile, setIsMobile] = useState(true);

  useLangRedirect(page);

  if (!page) {
    return null;
  }

  const {
    featuredImage,
    elementorDataRendered,
    elementorEditMode,
    lang,
    seo,
    booking,
    // pageOptions: { heroSize },
  } = page;

  const isCustomLayout = elementorEditMode === 'builder';
  const featuredMedia = featuredImage?.node;
  const itemImageSrc = getSrc(featuredMedia?.localFile);

  const withHero = true;

  const handleSticky = (isStuck) => {
    setIsStuck(isStuck);
  };

  const handleResize = () => {
    setIsMobile(
      typeof window !== 'undefined' &&
        window?.innerWidth < breakpoints.mediumlarge,
    );
  };

  useEffect(() => {
    handleResize(); // Trigger function on mount to get initial screen size

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Layout>
      <SEO
        title={seo.title}
        description={seo.metaDesc || seo.opengraphDescription}
        image={itemImageSrc}
        page={page}
        lang={lang}
        classes={[
          'template-package',
          isBookingStuck && isMobile ? 'sticky-bar' : null,
        ]}
      />

      {withHero && (
        <Hero
          heading={page.title}
          image={featuredMedia?.localFile}
          pageTitle
          // actions="Actions"
          tint
          preHeading={t('packagesTitle')}
          // big={heroSize === 'big'}
        />
      )}

      <GridContainer>
        <Root>
          <ContentCol>
            {isCustomLayout ? (
              <div
                dangerouslySetInnerHTML={{ __html: elementorDataRendered }}
              />
            ) : (
              <>
                {page.content && (
                  <PostContent
                    dangerouslySetInnerHTML={{ __html: page.content }}
                  />
                )}
              </>
            )}
          </ContentCol>
          <SidebarCol>
            {(booking.bookinginformation ||
              booking.packagetime ||
              booking.pricing ||
              booking.url) && (
              <Sticky
                stickyClassName="sticky"
                onFixedToggle={handleSticky}
                mode={isMobile ? 'bottom' : 'top'}
                boundaryElement={isMobile ? null : SidebarCol.toString()}
                stickyStyle={{
                  zIndex: 99,
                }}
                className="stickyBookingBox"
                positionRecheckInterval={400}
                dontUpdateHolderHeightWhenSticky={isMobile}
              >
                <BookingInformation className={isBookingStuck && 'stuck'}>
                  <SidebarInner>
                    <SidebarTitleMain>
                      {t('titlePackageBooking')}
                    </SidebarTitleMain>

                    {booking.packagetime && (
                      <SidebarSection className="section-time">
                        <SidebarTitle>{t('titlePackageTime')}</SidebarTitle>
                        <Period
                          dangerouslySetInnerHTML={{
                            __html: booking.packagetime,
                          }}
                        />
                      </SidebarSection>
                    )}

                    {booking.pricing && (
                      <SidebarSection className="section-pricing">
                        <SidebarTitle>{t('titlePackagePricing')}</SidebarTitle>
                        <Pricing
                          dangerouslySetInnerHTML={{ __html: booking.pricing }}
                        />
                      </SidebarSection>
                    )}

                    {booking.bookinginformation && (
                      <Description
                        dangerouslySetInnerHTML={{
                          __html: booking.bookinginformation,
                        }}
                      />
                    )}

                    {booking.url && (
                      <CtaButton link={booking.url} variant="fill">
                        {t('bookPackageButton')}
                      </CtaButton>
                    )}
                  </SidebarInner>
                </BookingInformation>
              </Sticky>
            )}
          </SidebarCol>
        </Root>
      </GridContainer>
    </Layout>
  );
}

Page.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
};

Page.fragments = [PackageContent, SiteInformation];

export const query = graphql`
  query ($id: String!) {
    page: wpPackage(id: { eq: $id }) {
      ...PackageContent
    }
    site {
      ...SiteInformation
    }
  }
`;

export default Page;
